import styled from 'styled-components';
import Label from 'components/atoms/Label';
import Link from 'components/atoms/Link';
import ButtonLink from 'components/atoms/ButtonLink';
import TextFormatter from 'components/atoms/TextFormatter';
import dayjs from 'dayjs';
import StaticContent from '../../atoms/StaticContent';

const Wrapper = styled.div`
  border: 0.0625em solid
    ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.neutral4)};
  border-radius: 0.25em;
  padding: 0.5em;
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 1em;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: -0.2rem -0.2rem 0;
`;

const Title = styled(Link)`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0.2rem;
  text-decoration: none;
  display: block;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  margin: 0.3rem 0.2rem 0;
  display: block;
`;

const StyledLabel = styled(Label)`
  margin: 0.2rem;
`;

const DetailsButton = styled(ButtonLink)`
  margin-top: 1em;
`;

const ShortDescription = styled.div`
  margin-top: 0.6em;
`;

const SaleCard = ({ sale, className, hideButton, hideLabel }) => {
  const now = dayjs();
  const startsAt = sale.startsAt && dayjs(sale.startsAt);
  const endsAt = sale.endsAt && dayjs(sale.endsAt);
  const isActive = (!endsAt || now.isBefore(endsAt)) && (!startsAt || now.isAfter(startsAt));
  const dates =
    (startsAt ? `с ${startsAt.format('DD MMM YYYY')}${endsAt ? ' ' : ''}` : '') +
    (endsAt ? `по ${endsAt.format('DD MMM YYYY')}` : '');

  return (
    <Wrapper isActive={isActive} className={className}>
      <FirstRow>
        <div>
          <Title to={`/sales/${sale.id}`}>{sale.name}</Title>
          <StaticContent>
            <Subtitle>{dates}</Subtitle>
          </StaticContent>
        </div>
        <StaticContent>
          {!hideLabel && isActive && endsAt && (
            <StyledLabel color="accent1">
              дней до конца акции: {endsAt.diff(now, 'day')}
            </StyledLabel>
          )}
          {!hideLabel && !isActive && now.isBefore(startsAt) && (
            <StyledLabel color="primary">Скоро!</StyledLabel>
          )}
          {!hideLabel && !isActive && now.isAfter(endsAt) && (
            <StyledLabel color="neutral4">Акция завершена</StyledLabel>
          )}
        </StaticContent>
      </FirstRow>
      {sale.shortDescription && (
        <ShortDescription>
          <TextFormatter text={sale.shortDescription} />
        </ShortDescription>
      )}
      {!hideButton && (
        <DetailsButton to={`/sales/${sale.id}`} inline neutral={!isActive} primary={isActive}>
          Подробнее
        </DetailsButton>
      )}
    </Wrapper>
  );
};

export default SaleCard;
