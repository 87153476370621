import React from 'react';
import { useHydrated } from '../../HydrationContext';

const StaticContent = ({ children, wrapper = 'div', ...props }) => {
  const isHydrated = useHydrated();

  if (isHydrated) {
    return React.createElement(
      wrapper,
      {
        ...props,
      },
      children,
    );
  }

  return React.createElement(wrapper, {
    ...props,
    suppressHydrationWarning: true,
    dangerouslySetInnerHTML: { __html: '' },
  });
};

export default StaticContent;
